import { Box, Card, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Column } from "../Section/column";
import { Row } from "../Section/row";

const AboutUsCardsContent: Array<AboutUsCardProps> = [
	{
		title: "Our Vision",
		description:
			"We envision a future where our contributions significantly enhance patient outcomes and set new standards of excellence in the medical field.",
	},
	{
		title: "Our Mission",
		description:
			"We aim to empower medical professionals with the equipment and tools needed to deliver superior patient care, and we strive to be the trusted partner in the medical community, ensuring access to the highest quality medical technology and services.",
	},
	{
		title: "Our Values",
		description:
			"Professionalism, Commitment, Integrity, Confidentiality, Accountability, Adaptation, Ethics and Honesty.",
	},
];

interface AboutUsCardProps {
	title: String;
	description: String;
	// onClick: CallableFunction;
}

function AboutUsCard(props: AboutUsCardProps) {
	return (
		<Box sx={{ width: "300px" }}>
			<Box
				className="animateOnScroll fademe"
				sx={{
					textAlign: "center",
				}}
			>
				<Typography
					// className="animateOnScroll fademe"
					variant="h3"
					sx={{
						fontWeight: "bold",
						"&": {
							background:
								"conic-gradient(at 42% 2%,#e84e0eeb 10%, #000000 141%)",
							WebkitBackgroundClip: "text",
							WebkitTextFillColor: "transparent",
						},
					}}
				>
					{props.title.split(" ")[0]}
					{/* Our */}
				</Typography>

				<Typography
					// className="animateOnScroll fademe"
					variant="h2"
					sx={{ fontWeight: "bold", mt: "-10px" }}
				>
					{props.title.split(" ")[1]}
					{/* Vision */}
				</Typography>
			</Box>

			<Box className="animateOnScroll fademe">
				<Card
					sx={{
						"&": {
							boxShadow:
								"0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
							transition:
								"box-shadow 0.3s ease-in-out !important",
						},

						"&:hover": {
							boxShadow:
								"0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
						},
					}}
				>
					<CardContent
						sx={{
							borderImage:
								"conic-gradient(at 0% 7%,#e84e0eeb 10%, #000000 141%) 1 100",

							padding: "20px",
							aspectRatio: "3/2",
							borderTop: "6px solid ",
							textAlign: "center",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{/* <CardContent> */}
						<Typography variant="subtitle1">
							{props.description}
						</Typography>
						{/* </CardContent> */}
					</CardContent>
				</Card>
			</Box>
			{/* </Box> */}
			{/* </Row> */}
			{/* <Row sx={{ width: "unset" }}> */}
			{/* <Card
				// className="animateOnScroll fademe"
				sx={{
					minWidth: "300px",
					position: "relative",
					top: "0px",
					width: "30%",
					// flexGrow: 1,
					// flexShrink: 0,
					overflow: "hidden",
					textAlign: "center",
					// cursor: "pointer",
					borderTop: "6px solid ",
					borderImage:
						"conic-gradient(at 0% 7%,#e84e0eeb 10%, #000000 141%) 1 100",

					transition:
						"top 2s ease-in-out, opacity 2s ease-in-out, box-shadow 0.3s ease-in-out !important",

					"&:hover": {
						boxShadow:
							"0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
						// borderTopWidth: "10px",
						"& .CardMedia": {
							height: "130px !important",
						},
					},
				}}
			>
				{/* <CardActionArea
				sx={{
					transition: "border-top-width 0.3s ease-in-out",
					// "&:hover": {
						// 	borderTopWidth: "10px",
						// },
					}}
				> */}
			{/* <CardContent
					// component={Link}
					// href={"/about-us#" + props.title}
					// onClick={() => props.onClick()}
					className="CardContent"
					sx={{
						display: "block",
						height: "200px",
						transition: "height 0.5s ease-in-out",
						textDecoration: "none",
					}}
				> */}
			{/* <Typography
					gutterBottom
					variant="h5"
					color="text.primary"
					sx={{ whiteSpace: "break-spaces", fontWeight: "bold" }}
					>
					{props.title}
				</Typography> */}

			{/* <Typography variant="body2" color="text.secondary">
						{props.description}
					</Typography>
				</CardContent> */}
			{/* </CardActionArea> */}
			{/* </Card>  */}
		</Box>
	);
}
export function AboutUs() {
	const navigate = useNavigate();
	return (
		<Column>
			{/* <Row className="animateOnScroll fademe">
				<Typography
				variant="h6"
				sx={{ color: "unset", textAlign: "center" }}
				>
				Our main focus is to provide you with a complete experience
					starting by supplying you with high-quality products, moving
					towards about us and scientific application support, and
					ending it with nonstop follow-up.
				</Typography>
			</Row> */}
			<Row>
				<Box
					sx={{
						width: "100%",
						height: "300px",
						display: "flex",
						overflow: "hidden",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Box
						sx={{
							height: "140%",
							mixBlendMode: "multiply",
							objectFit: "cover",
						}}
						component={"img"}
						src="assets/images/vision-mission-values.jpeg"
					></Box>
				</Box>
			</Row>
			<Row
				sx={{
					flexWrap: "wrap",
					justifyContent: "center",
					gap: "20px",
				}}
			>
				{AboutUsCardsContent.map((card) => {
					return (
						<AboutUsCard
							title={card["title"]}
							description={card["description"]}
							// onClick={() =>
							// 	navigate("/aboutus#" + card["title"], {
							// 		preventScrollReset: true,
							// 		replace: false,
							// 	})
							// }
						/>
					);
				})}
				{/* <About UsCard />
				<About UsCard />
				<About UsCard /> */}
			</Row>

			{/* <Row
				sx={{ justifyContent: "center" }}
				className="animateOnScroll fademe"
			>
				<Button
					variant="contained"
					onClick={() => navigate("/aboutus")}
				>
					Our About Us
				</Button>
			</Row> */}
		</Column>
	);
}
