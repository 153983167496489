import axios from "axios";
import { toast } from "react-toastify";

const HOST = window.document.location.origin;

export function makeRequest({
	endpoint,
	data = {},
	onResolve = (reponse: any) => {},
	onCatch = (reponse: any) => {},
	requestMessage = { error: "Something went wrong!, please contact us!" },
}: {
	endpoint: string;
	data?: any;
	onResolve?: CallableFunction;
	onCatch?: CallableFunction;
	requestMessage?: {
		pending?: string;
		success?: string;
		error?: string;
	};
}) {
	toast
		.promise(axios.post(HOST + endpoint, data), requestMessage)
		.then((response) => onResolve(response))
		.catch((response) => onCatch(response));
}
