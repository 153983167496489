import { Box, Container, SxProps, Typography } from "@mui/material";
import { ReactChild, ReactChildren } from "react";

export function Section({
	title,
	titlePrefix,
	children,
	background,
	sx,
	containerSx,
	id,
}: {
	title?: string;
	titlePrefix?: string;
	children: ReactChild | Array<ReactChild>;
	background?: ReactChild;
	sx?: SxProps;
	containerSx?: SxProps;
	id?: string;
}) {
	return (
		<Box
			id={id}
			className="SectionBox"
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				p: title ? "50px 0" : "50px 0",
				minHeight: "600px",
				...sx,
			}}
		>
			{titlePrefix ? (
				<Typography
					className="animateOnScroll fademe"
					variant="h3"
					sx={{
						fontWeight: "bold",
						"&": {
							background:
							"conic-gradient(at 8% 7%, rgb(210 85 2 / 92%) 10%, rgb(122 57 0) 141%) ",
							WebkitBackgroundClip: "text",
							WebkitTextFillColor: "transparent",
						},
					}}
				>
					{titlePrefix}
				</Typography>
			) : null}
			{title ? (
				<Typography
					className="animateOnScroll fademe"
					variant="h2"
					sx={{ fontWeight: "bold", mt: "-10px" }}
				>
					{title}
				</Typography>
			) : null}

			{background ? <Box>{background}</Box> : null}
			<Container
				maxWidth="lg"
				sx={{
					display: "flex",
					flexWrap: { xs: "wrap", md: "unset" },
					...containerSx,
				}}
			>
				{children}
			</Container>
		</Box>
	);
}
