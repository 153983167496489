import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, Button, Container, Link, Typography } from "@mui/material";
import { useEffect } from "react";
import { HeaderText } from "../../components/HeaderText/HeaderText";
import { Column } from "../../components/Section/column";
import { Divider } from "../../components/Section/divider";
import { Row } from "../../components/Section/row";
import { Section } from "../../components/Section/section";

export function PartnersPage() {
	useEffect(() => {
		document.title = "Fursan - Partners";
	}, []);
	const partners = [
		{
			name: "Agilent Dako",
			src: "assets/images/2x1/Agilent Dako 2x1.png",
			url: "https://www.agilent.com/en/dako-products/",
		},
		{
			name: "Alpina",
			src: "assets/images/2x1/Alpina 2x1.png",
		},
		{
			name: "BD Company",
			src: "assets/images/2x1/BD Company 2x1.png",
			url: "https://www.bd.com/en-us",
		},

		{
			name: "Binding Site",
			src: "assets/images/2x1/Binding Site 2x1.png",
			url: "https://www.thermofisher.com/bindingsite/gb/en/home.html",
		},
		{
			name: "Birr",
			src: "assets/images/2x1/Birr 2x1.png",
			url: "https://birr.nl/",
		},
		{
			name: "Cooper Surgical",
			src: "assets/images/2x1/Cooper Surgical 2x1.png",
			url: "https://www.coopersurgical.com/",
		},
		{
			name: "GS Biotech",
			src: "assets/images/2x1/GS Biotech 2x1.png",
			url: "www.gsbiotech.co.uk",
		},

		{
			name: "Planer",
			src: "assets/images/2x1/Planer 2x1.png",
			url: "https://planer.com",
		},
	];
	return (
		<Box
			sx={{
				background:
					"conic-gradient(at 0% 100%,  rgba(0, 0, 0, 30%) -15%,white 30%)",

				"& .SectionBox": {
					minHeight: 500,
				},
			}}
		>
			<Container>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						pt: " 100px",
					}}
				>
					<HeaderText
						title=""
						phrases={[
							"Together, We Achieve Healthcare Excellence",
							"Fursan For Medical Supplies",
						]}
					/>
				</Box>

				<Section titlePrefix="Join our" title="Partners">
					<Column>
						<Row className="animateOnScroll fademe">
							<Typography variant="h6" align="center">
							Partner with us to elevate your healthcare solutions. Reach out today to explore collaboration opportunities and discover how our expertise in the market matches your medical products and service, and to explore how we can support your business growth and innovation.
							</Typography>
						</Row>

						<Row
							sx={{ flexWrap: "wrap", justifyContent: "center" }}
						>
							{partners.map((partner, index) => (
								<Box
									key={index}
									className="animateOnScroll fademe"
									sx={{
										padding: "20px 0",
										margin: "0 16px",
										objectFit: "contain",
										width: "300px",

										maxHeight: "200px",
										height: "200px",
										minHeight: "200px",
									}}
									component={Link}
									href={partner.url}
								>
									<Box
										sx={{
											width: "100%",
											height: "100%",
											objectFit: "contain",
											filter: "saturate(0) contrast(0.5)",
											transition:
												" filter 0.3s ease-in-out",

											"&:hover": {
												filter: "saturate(1)",
											},
										}}
										component={"img"}
										src={partner.src}
									/>
								</Box>
							))}
						</Row>
					</Column>
				</Section>
			</Container>
		</Box>
	);
}
