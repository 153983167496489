import { Box, Typography } from "@mui/material";

export function StoryYear({ year }: { year: number }) {
	if (year <= 0) {
		return null;
	}
	return (
		<Box
			sx={{
				height: "100px",
				display: "flex",
				justifyContent: { xs: "flex-start", md: "center" },
			}}
		>
			<Box
				sx={{
					width: "100px",
					color: "white",
					background: "#4a4a49",
					borderRadius: "50%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Typography variant="h5">{year}</Typography>
			</Box>
		</Box>
	);
}
