import { Box, SxProps } from "@mui/material";
import { ReactNode } from "react";

export function Column({
	children,
	className,
	sx,
}: {
	children: any |ReactNode | Array<ReactNode> | Array<Element>;
	className?: string;
	sx?: SxProps;
}) {
	return (
		<Box
			className={"section-column " + className}
			sx={{
				// height: "600px",
				// padding: "50px 0",
				width: "100%",
				display: "flex",
				justifyContent: "center",
				flexGrow: 1,
				flexDirection: "column",
				textAlign: "left",
				alignItems: "flex-start",

				...sx,
			}}
		>
			{children}
		</Box>
	);
}
