import { Box, Container, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { HeaderText } from "../../components/HeaderText/HeaderText";
import { Column } from "../../components/Section/column";
import { Divider } from "../../components/Section/divider";
import { Row } from "../../components/Section/row";
import { Section } from "../../components/Section/section";

export function ServicesPage() {
	useEffect(() => {
		document.title = "Fursan - Services";
	}, []);

	return (
		<Box
			sx={{
				background:
					"conic-gradient(at 0% 100%,  rgba(0, 0, 0, 30%) -15%,white 30%)",
			}}
		>
			<Container>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						pt: " 100px",
					}}
				>
					<HeaderText
						title=""
						phrases={[
							"Empowering Healthcare and Innovation",
							"Fursan For Medical Supplies",
						]}
					/>
				</Box>

				{/* <Typography
					variant="h6"
					className="animateOnScroll fademe"
					sx={{
						p: 6,
						// pt: 6,
						textAlign: "center",
						whiteSpace: "break-spaces",
					}}
				>
					Provide our clients with complete experience starting from
					supplying high-quality products, moving towards service and
					scientific application support, and continuing follow-up.
				</Typography>
				<Divider className="animateOnScroll fademe" /> */}

				<Section id="Provisioning" titlePrefix="Provisioning" title="">
					<Row
						sx={{
							alignItems: "center",
							flexDirection: { xs: "row-reverse", md: "unset" },
							justifyContent: "center",
							flexWrap: { xs: "wrap", md: "nowrap" },
							margin: "16px 0",

							"& > *": {
								margin: { md: "20px !important", xs: "10px 0" },
								flexGrow: 1,
								maxWidth: { md: "50%", xs: "unset" },
								maxHeight: "400px",
							},
						}}
					>
						<Paper
							className="animateOnScroll fademe"
							sx={{
								aspectRatio: "5/3",
								width: "100%",
								objectFit: "cover",
							}}
							component={"img"}
							src="/assets/images/services/Modern_Laboratory_original_561033.jpg"
						></Paper>

						<Column className="animateOnScroll fademe">
							<Typography
								variant="h6"
								sx={{
									textAlign: "left",
									whiteSpace: "break-spaces",
								}}
							>
								Provide top-tier medical products and solutions, in partnership with leading International healthcare manufacturers to meet the customers’ demands and expectations.
							</Typography>
						</Column>
					</Row>
				</Section>
				<Divider className="animateOnScroll fademe" />

				<Section id="Support" titlePrefix="Support" title="">
					<Row
						sx={{
							alignItems: "center",
							justifyContent: "center",
							margin: "16px 0",
							flexDirection: { xs: "column-reverse", md: "row" },
							flexWrap: { xs: "wrap", md: "nowrap" },
							"& > *": {
								margin: { md: "20px !important", xs: "10px 0" },
								flexGrow: 1,
								maxWidth: { md: "50%", xs: "unset" },
							},
						}}
					>
						<Column className="animateOnScroll fademe">
							<Typography
								variant="h6"
								sx={{
									textAlign: "left",
									whiteSpace: "break-spaces",
								}}
							>
Strong scientific consultation team to guide you through the best suitable options for your needs. On demand maintenance team to troubleshoot any issues you may encounter and maintain a continuous seamless flow of your work. 							</Typography>
						</Column>
						<Paper
							className="animateOnScroll fademe"
							sx={{
								aspectRatio: "5/3",
								width: "100%",
								objectFit: "cover",
							}}
							component={"img"}
							src="/assets/images/services/Repairing_Broken_Drone_original_396914.jpg"
						/>
					</Row>
				</Section>
				<Divider className="animateOnScroll fademe" />
				<Section id="Follow-up" titlePrefix="Follow-up" title="">
					<Row
						sx={{
							alignItems: "center",
							flexDirection: { xs: "row-reverse", md: "unset" },
							justifyContent: "center",
							flexWrap: { xs: "wrap", md: "nowrap" },
							margin: "16px 0",

							"& > *": {
								margin: { md: "20px !important", xs: "10px 0" },
								flexGrow: 1,
								maxWidth: { md: "50%", xs: "unset" },
								maxHeight: "400px",
							},
						}}
					>
						<Paper
							className="animateOnScroll fademe"
							sx={{
								aspectRatio: "5/3",
								width: "100%",
								objectFit: "cover",
							}}
							component={"img"}
							src="/assets/images/services/Doctors_Shake_Hands_original_579129.jpg"
						></Paper>

						<Column className="animateOnScroll fademe">
							<Typography
								variant="h6"
								sx={{
									textAlign: "left",
									whiteSpace: "break-spaces",
								}}
							>
								Continuous after-sales follow up to ensure customer satisfaction and to ensure costumers being up-to-date with the latest advancements and technologies to improve their work’s success rate. 
							</Typography>
						</Column>
					</Row>
				</Section>
				{/* </Section> */}
			</Container>
		</Box>
	);
}
