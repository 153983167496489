import {
	Box,
	Button,
	TextField,
	ThemeProvider,
	Typography,
	createTheme,
} from "@mui/material";
import { Column } from "../Section/column";
import { Row } from "../Section/row";
import { Spacer } from "../Section/spacer";
import { useForm } from "react-hook-form";
import MuiPhoneNumber from "mui-phone-number";
import { useEffect, useState } from "react";
import { makeRequest } from "../../utils/request";

export function ContactUs({
	justForm,
	isDarkBackground,
}: {
	justForm?: boolean;
	isDarkBackground?: boolean;
}) {
	const {
		register,
		handleSubmit,
		getValues,
		setError,
		resetField,
		reset,
		formState: { errors },
	} = useForm();
	const [data, setData] = useState("");

	const darkTheme = createTheme({
		palette: {
			primary: {
				main: "#e84e0e",
			},
			mode: "dark",
		},
	});

	const [phoneNumberValue, setPhoneNumberValue] = useState("+962");

	return (
		<>
			{justForm ? null : (
				<>
					<Column
						sx={{
							textAlign: { xs: "center", md: "left" },
							alignItems: { xs: "center", md: "flex-start" },
						}}
					>
						<Typography
							className="animateOnScroll fademe"
							variant="h1"
							sx={{
								fontWeight: "bold",
								"&": {
									background:
										"conic-gradient(at 42% 2%,#e84e0eeb 10%, #000000 141%)",
									WebkitBackgroundClip: "text",
									WebkitTextFillColor: "transparent",
								},
							}}
						>
							Contact Us
						</Typography>
						<Typography
							className="animateOnScroll fademe"
							variant="subtitle1"
							sx={{ p: "0 16px", m: "20px 0" }}
						>
Contact us to discover cutting-edge medical solutions tailored to your needs. Our expert team is ready to collaborate and help you achieve excellence in healthcare.
							<br></br>
						</Typography>
						<Typography
							className="animateOnScroll fademe"
							variant="body2"
							sx={{ p: "0 16px" }}
						>
							Email: info@fursan-med.com
							<br></br>
							Mailing Address: P.O. Box 541303 Amman 11937, Jordan
							<br></br>
							Phone: +962 6 5857500<br></br>
							Fax: +962 6 5857505<br></br>
						</Typography>
					</Column>
					<Spacer h={70} v={10} />
				</>
			)}
			<Column>
				<ThemeProvider theme={isDarkBackground ? darkTheme : {}}>
					<Box
						component={"form"}
						sx={{
							width: "100%",
						}}
						onSubmit={handleSubmit((data) => {
							if (getValues("phone_number").length <= 12) {
								setError("phone_number", {
									type: "manual",
									message: "Invalid phon css-igs3ace number",
								});

								return false;
							}
							makeRequest({
								endpoint: "/api/contact-us/insert",
								data: data,
								requestMessage: {
									pending: "Sending...",
									success: `Hey ${data["first_name"]} ${data["last_name"]} your message was sent!`,
								},
								onResolve: () => {
									setPhoneNumberValue("+962");

									reset();
								},
							});
						})}
					>
						<Row className="animateOnScroll fademe">
							<Row>
								<TextField
									fullWidth
									{...register("first_name")}
									// name="first_name"

									label="First Name"
									variant="outlined"
									required
								/>
							</Row>
							<Spacer size={10} />
							<Row>
								<TextField
									fullWidth
									{...register("last_name")}
									// name="last_name"
									label="Surname"
									variant="outlined"
									required
								/>
							</Row>
						</Row>
						<Row className="animateOnScroll fademe">
							<TextField
								fullWidth
								{...register("email")}
								// name="email"
								type="email"
								label="Email"
								variant="outlined"
								required
							/>
						</Row>
						<Row className="animateOnScroll fademe">
							<MuiPhoneNumber
								// ref={}
								// component={TextField}
								helperText={
									<>
										{errors!["phone_number"] &&
											errors!["phone_number"]["message"]}
									</>
								}
								error={Boolean(errors["phone_number"])}
								required
								autoFormat={true}
								disableAreaCodes={true}
								excludeCountries={["il"]}
								sx={{ width: "100%" }}
								inputProps={register("phone_number")}
								FormHelperTextProps={{
									sx: {
										direction: "rtl",
									},
								}}
								value={phoneNumberValue}
								onChange={(value) => {
									setPhoneNumberValue(value.toString());
								}}
								dir="ltr"
								// label="Phone Number"
								// label={input.label}

								variant="outlined"
								defaultCountry="jo"
							/>
						</Row>
						<Row className="animateOnScroll fademe">
							<TextField
								{...register("message")}
								// name="message"
								fullWidth
								label="Message"
								variant="outlined"
								multiline
								required
								rows={5}
							/>
						</Row>
						<Button
							type="submit"
							className="animateOnScroll fademe"
							variant="contained"
							sx={{ width: "100%" }}
						>
							Send
						</Button>
					</Box>
				</ThemeProvider>
			</Column>
		</>
	);
}
