import { Box } from "@mui/material";

export function Spacer({
	size,
	h,
	v,
}: {
	size?: number;
	h?: number;
	v?: number;
}) {
	return (
		<Box
			sx={{
				width: `${h ? h : size}px`,
				height: `${v ? v : size}px`,
				flexShrink: 0,
			}}
		></Box>
	);
}
