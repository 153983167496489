export const enum DrawerType {
    NEW,
    UPDATE
}


export const enum StoryType {
    NORMAL,
    MILESTONE
}
