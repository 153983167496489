import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContactUs } from "../../components/ContactUs/ContactUs";
import { Countries } from "../../components/Countries/Countries";
import { DNA } from "../../components/DNA/dna";
import { Column } from "../../components/Section/column";
import { Row } from "../../components/Section/row";
import { Section } from "../../components/Section/section";
import { Services } from "../../components/Services/services";
import { VideoBanner } from "../../components/VideoBanner/VideoBanner";

export function HomePage() {
	const navigate = useNavigate();

	useEffect(() => {
		document.title = "Fursan Med";
	}, []);

	return (
		<Box
			sx={{
				mt: "30px",
				overflow: "hidden",
			}}
		>
			<VideoBanner />

			<Section
				sx={{
					background: "black",
					//"conic-gradient(at right top, rgb(56, 114, 172), rgba(0, 0, 0, 0.96))",
					color: "white",
					// pb: 0,
				}}
				containerSx={{ p: 0 }}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						flexGrow: 1,
						flexDirection: "column",

						textAlign: { xs: "center", md: "left" },
						alignItems: { xs: "center", md: "flex-start" },
						zIndex: 1,
					}}
				>
					<Column>
						<Row sx={{ textAlign: { xs: "center", md: "left" } }}>
							<Typography
								className="animateOnScroll fademe"
								variant="h2"
								sx={{
									fontWeight: "bold",
									textAlign: "unset",
									p: "0 16px",
								}}
							>
								Fursan’s DNA
							</Typography>
						</Row>
						<Row sx={{ display: { xs: "flex", md: "none" } }}>
							<DNA />
						</Row>

						<Row
							sx={{
								textAlign: {
									xs: "center",
									md: "left",
								},
								p: "0 16px",
							}}
						>
							<Typography
								className="animateOnScroll fademe"
								variant="h6"
							>
								<p>
									Our journey in excellence started in 1996,
									where Al Fursan for Medical Supplies began
									making its mark by supplying IVF and ART
									products, along with a genomics line. Over
									the years, we expanded our offerings to
									include dedicated pathology lines,
									establishing ourselves as a leader in the
									industry.
								</p>
								<p>
									Initially serving the private healthcare
									sector, we soon broadened our reach to
									include the public sector, becoming one of
									the leading companies in Jordan.
								</p>
								<p>
									In 2012, we extended our operations to Iraq
									by establishing subsidiary offices, AlShafaq
									and FurMed, in Baghdad and Kurdistan
									(Erbil). This expansion allowed us to
									successfully penetrate the Iraqi market and
									serve its healthcare needs.
								</p>
								<p>
									We consider our partners as the backbone of
									our success, and over the years, we have
									expanded this foundation to include a
									diverse range of top-tier products and
									services.{" "}
								</p>
							</Typography>
						</Row>

						<Row
							sx={{
								p: "0 16px",
								justifyContent: {
									xs: "center",
									md: "flex-start",
								},
							}}
						>
							<Button
								sx={{}}
								className="animateOnScroll fademe"
								variant="contained"
								onClick={() => navigate("/partners")}
							>
								Our Partners
							</Button>
						</Row>
					</Column>
				</Box>
				<Row sx={{ display: { xs: "none", md: "flex" } }}>
					<DNA />
				</Row>
			</Section>
			{/* <Section
				sx={{
					background:
						"conic-gradient(at right top, rgb(56, 114, 172), rgba(0, 0, 0, 0.96))",
					color: "white",
					pb: 0,
					position: "relative",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						flexGrow: 1,
						flexDirection: "column",

						textAlign: { xs: "center", md: "left" },
						alignItems: { xs: "center", md: "flex-start" },
						zIndex: 1,
					}}
				>
					<Column>
						<Row sx={{ textAlign: { xs: "center", md: "left" } }}>
							<Typography
								className='animateOnScroll fademe'
								variant='h2'
								sx={{ fontWeight: "bold", textAlign: "unset" }}
							>
								Fusran's DNA Consist of 50+ Partners
							</Typography>
						</Row>
						<Row sx={{ textAlign: { xs: "center", md: "left" } }}>
							<Typography
								className='animateOnScroll fademe'
								variant='h6'
							>
								Quis fugiat voluptate tempor aliquip quis sit id
								sint proident. Aute fugiat eiusmod ex laboris
								mollit amet laboris minim qui quis deserunt
								Lorem incididunt. Nisi exercitation qui
								adipisicing adipisicing velit minim
								reprehenderit exercitation dolor sunt
								exercitation est. Fugiat exercitation officia
								sint irure est cillum mollit nisi esse. Ex magna
								irure labore anim laboris.
							</Typography>
						</Row>
						<Row
							sx={{
								justifyContent: {
									xs: "center",
									md: "flex-start",
								},
							}}
						>
							<Button
								className='animateOnScroll fademe'
								variant='contained'
								onClick={() => navigate("/partners")}
							>
								Our Partners
							</Button>
						</Row>
					</Column>
				</Box>
				<DNA />
			</Section> */}
			{/* <Section
				titlePrefix="Al Fursan"
				title="Backbone"
				sx={{
					background:
						"conic-gradient(at 0% 100%,  rgba(0, 0, 0, 30%) -15%,white 30%)",
				}}
			>
				<Services />
			</Section> */}
			<Section
				titlePrefix="Join"
				title="Our Clients"
				sx={{
					background:
						"conic-gradient(at 0% 100%,  rgba(0, 0, 0, 30%) -15%,white 30%)",
					maxHeight: "unset",
				}}
				// sx={{
				// 	color: "white",
				// 	background:
				// 		"conic-gradient(at right top, rgb(137, 166, 195), rgb(0, 0, 0))",
				// }}
			>
				<Countries />
			</Section>
			{/* <Section
				sx={{
					background:
						"conic-gradient(at right top, rgb(56, 114, 172), rgba(0, 0, 0, 0.96))",
					color: "white",
					// pb: 0,
				}}
			>
				<ContactUs />
			</Section> */}
		</Box>
	);
}

//conic-gradient(at right top, #2c0d00, #e84e0e)
