import { Box, Paper } from "@mui/material";

export function GoogleMaps() {
	return (
		<Box
			className='mapouter animateOnScroll fademe'
			sx={{
				position: "relative",
				textAlign: "right",
				height: "100%",
				width: "100%",
			}}
		>
			<Box
				className='gmap_canvas'
				sx={{
					overflow: "hidden",
					background: "none!important",
					height: "100%",
					width: "100%",
				}}
			>
				<Paper
					elevation={5}
					component={"iframe"}
					width='100%'
					height='100%'
					id='gmap_canvas'
					src='https://maps.google.com/maps?q=31.967432, 35.842565&t=&z=14&ie=UTF8&iwloc=&output=embed'
					frameBorder='0'
					sx={{
						minHeight: 600,
					}}
				/>
				{/*             
            <style>.mapouter{position:relative;text-align:right;height:100%;width:100%;}</style>
            <style>.gmap_canvas {overflow:hidden;background:none!important;height:100%;width:100%;}
             */}
				{/* </style> */}
			</Box>
		</Box>
	);
}
