import { Box } from "@mui/material";
import "./style.scss";
// import image from "../../assets/images/khcf.jpg";

interface Company {
	name?: string;
	src?: string;
	url?: string;
}

const companies: Array<Company> = [
	{
		name: "Agilent Dako",
		src: "assets/images/1x1/Agilent Dako 1x1.png",
		url: "https://www.agilent.com/en/dako-products/",
	},
	{
		name: "Alpina",
		src: "assets/images/1x1/Alpina 1x1.png",
	},
	{
		name: "BD Company",
		src: "assets/images/1x1/BD Company 1x1.png",
		url: "https://www.bd.com/en-us",
	},

	{
		name: "Binding Site",
		src: "assets/images/1x1/Binding Site 1x1.png",
		url: "https://www.thermofisher.com/bindingsite/gb/en/home.html",
	},
	{
		name: "Birr",
		src: "assets/images/1x1/Birr 1x1.png",
		url: "https://birr.nl/",
	},
	{
		name: "Cooper Surgical",
		src: "assets/images/1x1/Cooper Surgical 1x1.png",
		url: "https://www.coopersurgical.com/",
	},
	{
		name: "GS Biotech",
		src: "assets/images/1x1/GS Biotech 1x1.png",
		url: "www.gsbiotech.co.uk",
	},
	{
		name: "Planer",
		src: "assets/images/1x1/Planer 1x1.png",
		url: "https://planer.com",
	},

	{
		name: "Agilent Dako",
		src: "assets/images/1x1/Agilent Dako 1x1.png",
		url: "https://www.agilent.com/en/dako-products/",
	},
	{
		name: "Alpina",
		src: "assets/images/1x1/Alpina 1x1.png",
	},
	{
		name: "BD Company",
		src: "assets/images/1x1/BD Company 1x1.png",
		url: "https://www.bd.com/en-us",
	},

	{
		name: "Binding Site",
		src: "assets/images/1x1/Binding Site 1x1.png",
		url: "https://www.thermofisher.com/bindingsite/gb/en/home.html",
	},
	{
		name: "Birr",
		src: "assets/images/1x1/Birr 1x1.png",
		url: "https://birr.nl/",
	},
	{
		name: "Cooper Surgical",
		src: "assets/images/1x1/Cooper Surgical 1x1.png",
		url: "https://www.coopersurgical.com/",
	},
	{
		name: "GS Biotech",
		src: "assets/images/1x1/GS Biotech 1x1.png",
		url: "www.gsbiotech.co.uk",
	},
	{
		name: "Planer",
		src: "assets/images/1x1/Planer 1x1.png",
		url: "https://planer.com",
	},
	{
		name: "Agilent Dako",
		src: "assets/images/1x1/Agilent Dako 1x1.png",
		url: "https://www.agilent.com/en/dako-products/",
	},
	{
		name: "Alpina",
		src: "assets/images/1x1/Alpina 1x1.png",
	},
	{
		name: "BD Company",
		src: "assets/images/1x1/BD Company 1x1.png",
		url: "https://www.bd.com/en-us",
	},

	{
		name: "Binding Site",
		src: "assets/images/1x1/Binding Site 1x1.png",
		url: "https://www.thermofisher.com/bindingsite/gb/en/home.html",
	},
	{
		name: "Birr",
		src: "assets/images/1x1/Birr 1x1.png",
		url: "https://birr.nl/",
	},
	{
		name: "Cooper Surgical",
		src: "assets/images/1x1/Cooper Surgical 1x1.png",
		url: "https://www.coopersurgical.com/",
	},
	{
		name: "GS Biotech",
		src: "assets/images/1x1/GS Biotech 1x1.png",
		url: "www.gsbiotech.co.uk",
	},
	{
		name: "Planer",
		src: "assets/images/1x1/Planer 1x1.png",
		url: "https://planer.com",
	},
];
export function DNA() {
	const OFFSET = 6;

	const offsetArray = [];

	const companyPairs = [];

	for (let i = 0; i < companies.length; i++) {
		if (i % 2 === 0) {
			companyPairs.push([companies[i]]);
		} else {
			companyPairs[companyPairs.length - 1].push(companies[i]);
		}
	}

	for (let i = 0; i < OFFSET; i++) {
		offsetArray.push(i);
	}

	// console.log(companyPairs, offsetArray);
	return (
		<Box
			className="DNA animateOnScreen onScreen"
			sx={{
				"--rotation": { xs: "-30deg", md: "-30deg" },
				minWidth: { xs: "unset", md: "600px" },
				// position: { xs: "relative", md: "unset" },
				// top: { xs: "-170px", md: "unset" },
				"--unit": { xs: "calc(30px + 25px)", md: "calc(40px + 25px)" },
				WebkitMaskImage: {
					xs: "linear-gradient(180deg,transparent 0% , #00000052 25%, #00000052 75%, transparent 100%)",
					md: "unset",
				},
				mb: { xs: "-80px", md: "unset" },
				mt: { xs: "-170px", md: "unset" },
			}}
		>
			<main>
				{companyPairs.map((pair, index) => {
					return (
						<section key={index}>
							<Box
								component="a"
								href={pair[0]?.url ? pair[0].url : undefined}
								target="_blank"
								className="node"
								data-name={pair[0]?.name ? pair[0].name : null}
							>
								{pair[0]?.src ? (
									<Box
										component="img"
										src={pair[0].src}
										alt=""
									></Box>
								) : null}
							</Box>
							<Box
								component="a"
								href={pair[1]?.url ? pair[1].url : undefined}
								target="_blank"
								className="node"
								data-name={pair[1]?.name ? pair[1].name : null}
							>
								{pair[1]?.src ? (
									<Box
										component="img"
										src={pair[1].src}
										alt=""
									></Box>
								) : null}
							</Box>
						</section>
					);
				})}
			</main>
		</Box>
	);
}
