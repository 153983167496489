import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	ListItemIcon,
	MenuItem,
	TextField,
} from "@mui/material";

import { useEffect, useRef, useState } from "react";
import { Row } from "../../components/Section/row";

import { Close, Delete, Save, Cancel, Add } from "@mui/icons-material";
import Drawer from "@mui/material/Drawer";
import { Column } from "../../components/Section/column";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Spacer } from "../../components/Section/spacer";
import { IPost, Post } from "./Post";
import { StoryBody } from "./StoryBody";
import { DrawerType, StoryType } from "./constants";
import axios from "axios";
import { makeRequest } from "../../utils/request";

export function StoryDrawer({
	post,
	setPosts,
	setReload,
	handleDrawerToggle,
	openState,
	drawerType,
}: {
	post: IPost;
	setPosts: CallableFunction;
	setReload: CallableFunction;
	handleDrawerToggle: CallableFunction;
	openState: boolean;
	drawerType: DrawerType;
}) {
	const drawerWidth = 640;

	const container =
		window !== undefined ? () => window.document.body : undefined;

	const [dateValue, setDateValue] = useState(
		post.date
			.toLocaleDateString("en-uk")
			.toString()
			.split("/")
			.reverse()
			.join("-")
	);
	const dateController = useRef();

	const [briefValue, setBriefValue] = useState(post.brief);
	const briefController = useRef();

	const [descriptionValue, setDescriptionValue] = useState(post.description);
	const descriptionController = useRef();

	const [typeValue, setTypeValue] = useState<StoryType | string | number>(
		post.type
	);
	const typeController = useRef();

	const [reviewPost, setReviewPost] = useState<Post>(
		new Post(
			(post = { id: post.id, date: new Date(), type: StoryType.NORMAL })
		)
	);

	useEffect(() => {
		setReviewPost(
			new Post(
				(post = {
					id: post.id,
					date: new Date(dateValue),
					description: descriptionValue,
					brief: briefValue,
					type: typeValue,
				})
			)
		);

		// console.log(reviewPost, "******");
	}, [dateValue, descriptionValue, briefValue, typeValue]);

	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

	const handleClickOpenDeleteConfirmation = () => {
		setOpenDeleteConfirmation(true);
	};

	const handleClickCloseDeleteConfirmation = () => {
		setOpenDeleteConfirmation(false);
	};

	return (
		<Drawer
			container={container}
			variant="persistent"
			open={openState}
			onClose={() => handleDrawerToggle()}
			anchor="right"
			ModalProps={{
				keepMounted: true, // Better open performance on mobile.
			}}
			sx={{
				"& .MuiDrawer-paper": {
					boxSizing: "border-box",
					width: drawerWidth,
					backdropFilter: "blur(5px)",
					bgcolor: "#e9e9e9e0",
				},
			}}
		>
			<Column sx={{ justifyContent: "space-between" }}>
				<Column
					sx={{
						justifyContent: "flex-start",
						height: "fit-content",
						flexGrow: 0,
					}}
				>
					<Row>
						<List sx={{ width: "100%" }}>
							<ListItem key={"exit"} disablePadding>
								<ListItemButton
									onClick={() => {
										handleDrawerToggle();
									}}
								>
									<ListItemIcon>
										<Close></Close>
									</ListItemIcon>
									<ListItemText primary={"Dismiss"} />
								</ListItemButton>
							</ListItem>
						</List>
					</Row>
				</Column>
				<Column sx={{ justifyContent: "space-between" }}>
					<Column sx={{ justifyContent: "flex-start" }}>
						<StoryBody post={reviewPost} />

						<Row sx={{ p: "0 30px" }}>
							<Column>
								<Row className="animateOnScroll fademe">
									<Row>
										<TextField
											fullWidth
											id=""
											label="Date"
											variant="outlined"
											type="date"
											required
											inputRef={dateController}
											defaultValue={dateValue}
											onChange={(e) => {
												setDateValue(e.target.value);
											}}
										/>
									</Row>
									<Spacer size={10} />
									<Row>
										<TextField
											fullWidth
											id="standard-basic"
											label="Type"
											required
											variant="outlined"
											defaultValue={typeValue}
											onChange={(e) => {
												setTypeValue(e.target.value);
											}}
											select
										>
											<MenuItem value="0">
												Normal
											</MenuItem>
											<MenuItem value="1">
												Milestone
											</MenuItem>
										</TextField>
									</Row>
								</Row>

								<Row className="animateOnScroll fademe">
									<TextField
										fullWidth
										id="standard-basic"
										label="Brief"
										variant="outlined"
										multiline
										rows={3}
										required
										inputRef={briefController}
										defaultValue={briefValue}
										onChange={(e) => {
											setBriefValue(e.target.value);
										}}
									/>
								</Row>
								<Row className="animateOnScroll fademe">
									<TextField
										fullWidth
										id="standard-basic"
										label="Description"
										variant="outlined"
										multiline
										rows={5}
										required
										inputRef={descriptionController}
										defaultValue={descriptionValue}
										onChange={(e) => {
											setDescriptionValue(e.target.value);
										}}
									/>
								</Row>
							</Column>
						</Row>
					</Column>
					<Row sx={{ justifyContent: "center" }}>{errorMessage}</Row>
					<Row
						className="animateOnScroll fademe"
						sx={{ p: "15px 30px" }}
					>
						{drawerType == DrawerType.UPDATE ? (
							<>
								<Button
									variant="contained"
									sx={{ width: "100%" }}
									disabled={loading}
									startIcon={
										loading ? (
											<CircularProgress
												size={20}
												sx={{ color: "white" }}
											/>
										) : (
											<Save />
										)
									}
									onClick={() => {
										setLoading(true);

										makeRequest({
											endpoint: `/api/timeline/update/${reviewPost.id}`,
											data: {
												type: reviewPost.type.toString(),
												date: reviewPost.date.valueOf(),
												brief: reviewPost.brief,
												description:
													reviewPost.description,
											},
											onResolve: (response: any) => {
												setReload(
													(prev: number) => prev + 1
												);
												handleDrawerToggle();
												setLoading(false);
											},
											onCatch: (err: any) => {
												console.log(err);
												setErrorMessage(err.toString());
												setLoading(false);
											},
										});
									}}
								>
									Update
								</Button>
								<Spacer size={10} />

								<Button
									variant="contained"
									sx={{
										width: "100%",
										"&.MuiButton-contained": {
											background:
												"conic-gradient(at 0% 0%,#919191eb 10%, #000000 141%)",
										},
									}}
									startIcon={<Cancel />}
									onClick={() => {
										handleDrawerToggle();
									}}
								>
									Cancel
								</Button>
								<Spacer size={10} />

								<Button
									variant="contained"
									sx={{
										width: "fit-content",
										"&.MuiButton-contained": {
											background:
												"conic-gradient(at 0% 0%,#e80e0eeb 10%, #000000 141%)",
										},
									}}
									disabled={loading}
									onClick={() => {
										setOpenDeleteConfirmation(true);
									}}
								>
									{loading ? (
										<CircularProgress
											size={20}
											sx={{ color: "white" }}
										/>
									) : (
										<Delete />
									)}
								</Button>
								<Dialog
									open={openDeleteConfirmation}
									onClose={handleClickCloseDeleteConfirmation}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
								>
									<DialogTitle id="alert-dialog-title">
										{
											" Are you sure you want to permanently delete this?"
										}
									</DialogTitle>
									<DialogContent>
										<DialogContentText id="alert-dialog-description">
											Do you want to continue?
										</DialogContentText>
									</DialogContent>
									<DialogActions>
										<Button
											variant="contained"
											color="error"
											onClick={() => {
												setLoading(true);

												makeRequest({
													endpoint: `/api/timeline/delete/${reviewPost.id}`,
													data: {},
													onResolve: (
														response: any
													) => {
														setReload(
															(prev: number) =>
																prev + 1
														);
														handleDrawerToggle();
														setLoading(false);
														handleClickCloseDeleteConfirmation();
													},
													onCatch: (err: any) => {
														console.log(err);
														setErrorMessage(
															err.toString()
														);
														setLoading(false);
														handleClickCloseDeleteConfirmation();
													},
												});
											}}
										>
											DELETE
										</Button>

										<Button
											variant="contained"
											onClick={() =>
												handleClickCloseDeleteConfirmation()
											}
										>
											Cancel
										</Button>
									</DialogActions>
								</Dialog>
							</>
						) : (
							<>
								<Button
									variant="contained"
									sx={{ width: "100%" }}
									disabled={loading}
									startIcon={
										loading ? (
											<CircularProgress
												size={20}
												sx={{ color: "white" }}
											/>
										) : (
											<Add />
										)
									}
									onClick={() => {
										setLoading(true);

										makeRequest({
											endpoint: `/api/timeline/insert/`,
											data: {
												type: reviewPost.type.toString(),
												date: reviewPost.date.valueOf(),
												brief: reviewPost.brief,
												description:
													reviewPost.description,
											},
											onResolve: (response: any) => {
												setReload(
													(prev: number) => prev + 1
												);
												handleDrawerToggle();
												setLoading(false);
											},
											onCatch: (err: any) => {
												console.log(err);
												setErrorMessage(err.toString());
												setLoading(false);
											},
										});
									}}
								>
									Add
								</Button>
								<Spacer size={10} />

								<Button
									variant="contained"
									sx={{
										width: "100%",
										"&.MuiButton-contained": {
											background:
												"conic-gradient(at 0% 0%,#919191eb 10%, #000000 141%)",
										},
									}}
									startIcon={<Cancel />}
									onClick={() => {
										handleDrawerToggle();
									}}
								>
									Cancel
								</Button>
							</>
						)}
					</Row>
				</Column>
			</Column>
		</Drawer>
	);
}
