import {
	Box,
} from "@mui/material";


import { StoryType } from "./constants";

export function StoryItemArrow({
	direction,
	isAt,
    type,
}: {
	direction: string;
	isAt: string;
    type:StoryType|string|number;
}) {
	return (
		<Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
			<Box
				sx={{
					marginRight:
						direction == isAt && isAt == "right" ? "-10px" : "10px",
					marginLeft:
						direction == isAt && isAt == "left" ? "-10px" : "10px",

					borderTop: "20px solid transparent",
					transform: "rotate(45deg)",
					zIndex: "1",

					borderImage:
						direction == isAt && isAt == "right"
							? "conic-gradient(at 35% -50%,"+ (type == StoryType.NORMAL ?"#e84e0e":"#818181")+" 10%, #000000 141%)"
							: "conic-gradient(at -50% 0%,"+ (type == StoryType.NORMAL ?"#e84e0e":"#818181")+" 10%, #000000 141%)",

					borderLeft:
						direction == isAt && isAt == "left"
							? "20px solid"
							: "none",
					borderRight:
						direction == isAt && isAt == "right"
							? "20px solid"
							: "none",
				}}
			/>
		</Box>
	);
}

