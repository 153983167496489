import { Box, SxProps } from "@mui/material";
import { ReactChild } from "react";

export function Row({
	children,
	className = "",
	sx,
}: {
	children: ReactChild | Array<ReactChild>;
	className?: string;
	sx?: SxProps;
}) {
	return (
		<Box
			className={"section-row " + className}
			sx={{
				width: "100%",
				display: "flex",
				justifyContent: "space-between",
				margin: "5px 0",

				...sx,
			}}
		>
			{children}
		</Box>
	);
}
