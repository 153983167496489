import {
	Box,
	Button,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	CardMedia,
	Container,
	Fab,
	IconButton,
	ListItemIcon,
	Paper,
	Skeleton,
	TextField,
	Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Row } from "../../components/Section/row";

import EmailIcon from "@mui/icons-material/Email";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import Collapse from "@mui/material/Collapse";
import { HeaderText } from "../../components/HeaderText/HeaderText";
import { Divider } from "../../components/Section/divider";
import { Image } from "../../interfaces/ImageSlider";
import {
	AddIcCallOutlined,
	Edit,
	ExitToApp,
	Close,
	Delete,
	Save,
	Add,
} from "@mui/icons-material";
import Drawer from "@mui/material/Drawer";
import { Column } from "../../components/Section/column";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Spacer } from "../../components/Section/spacer";
import axios from "axios";
import { IPost, Post } from "./Post";
import { StoryYear } from "./StoryYear";
import { StoryItem } from "./StoryItem";
import { StoryDrawer } from "./StoryDrawer";
import { DrawerType, StoryType } from "./constants";
import { makeRequest } from "../../utils/request";

var admin = true;

export function NewsPage({ is_admin = false }: { is_admin: boolean }) {
	useEffect(() => {
		document.title = "Fursan - Achievements";
	}, []);

	const [posts, setPosts] = useState<Array<Post>>([]);
	const [reload, setReload] = useState(0);

	useEffect(() => {
		const animateOnScrollObserver = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add("animateOnScrollTriggered");
				}
			});
		});
		const onScrollables = document.querySelectorAll(".animateOnScroll");
		onScrollables.forEach((section) =>
			animateOnScrollObserver.observe(section)
		);
	}, [posts]);

	let lastYear: number = -1;

	useEffect(() => {
		makeRequest({
			endpoint: "/api/timeline/get",
			requestMessage: {
				pending: "Loading...",
			},
			onResolve: (response: any) => {
				setPosts(
					response.data.map((post: any) => new Post({ ...post }))
				);
			},
		});
	}, [reload]);

	const [openState, setOpenState] = useState(false);

	const handleDrawerToggle = () => {
		setOpenState((prevState) => !prevState);
	};

	useEffect(() => {}, [posts]);

	return (
		<Box
			sx={{
				background:
					"conic-gradient(at 0% 100%,  rgba(0, 0, 0, 30%) -15%,white 30%)",
				minHeight: "200vh",
			}}
		>
			{is_admin ? (
				<>
					<Fab
						sx={{
							position: "fixed",
							bottom: "25px",
							right: "25px",
						}}
						color="primary"
						aria-label="add"
						onClick={handleDrawerToggle}
					>
						<Add />
					</Fab>

					<StoryDrawer
						setReload={setReload}
						setPosts={setPosts}
						drawerType={DrawerType.NEW}
						openState={openState}
						handleDrawerToggle={handleDrawerToggle}
						post={
							new Post({
								date: new Date(),
								type: StoryType.NORMAL,
							})
						}
					/>
				</>
			) : null}
			<Container>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						pt: " 100px",
					}}
				>
					<HeaderText
						title=""
						phrases={[
							"Milestones of Excellence!",
							"Our Success, Your Assurance.",
						]}
					/>
				</Box>
				<Box sx={{ py: "40px" }}>
					{posts.length === 0 ? (
						<SkeletonAchievements />
					) : (
						posts.map((item, index) => {
							if (item.date.getFullYear() !== lastYear) {
								lastYear = item.date.getFullYear();
								return [
									<StoryYear
										key={lastYear}
										year={lastYear}
									/>,
									<StoryItem
										key={item.id}
										setPosts={setPosts}
										is_admin={is_admin}
										setReload={setReload}
										post={item}
										direction={index % 2 ? "left" : "right"}
									/>,
								];
							} else {
								return (
									<StoryItem
										key={item.id}
										is_admin={is_admin}
										setPosts={setPosts}
										setReload={setReload}
										post={item}
										direction={index % 2 ? "left" : "right"}
									/>
								);
							}
						})
					)}

					<StoryYear year={lastYear - 1} />
				</Box>
			</Container>
		</Box>
	);
}

function SkeletonAchievements() {
	return (
		<Column sx={{ alignItems: { xs: "flex-start", md: "center" } }}>
			<Skeleton width={100} height={100} variant="circular"></Skeleton>
			{[1, 2, 3, 4, 5].map((item) => (
				<>
					{" "}
					<Box
						sx={{
							display: "flex",
							width: "100%",
						}}
					>
						<Box
							sx={{
								width: { xs: "0", md: "100%" },

								// width: "100%",
								height: "150px",
								p: { xs: "8px 21.5px", md: "8px 16px" },
							}}
						>
							<Skeleton
								variant="rounded"
								sx={{
									display: "none",
									width: "100%",
									height: "100%",
								}}
							></Skeleton>
						</Box>
						<Box
							sx={{
								// width: "5px",
								position: "relative",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								height: "150px",
								flexShrink: 0,
							}}
						>
							<Skeleton
								variant="rectangular"
								sx={{
									width: "5px",
									height: "67.5px",
									flexShrink: 0,
								}}
							></Skeleton>
							<Skeleton
								variant="circular"
								sx={{
									// position: "absolute",
									// top: "calc(50% - 7.5px)",
									width: "15px",
									height: "15px",
									flexShrink: 0,
								}}
							></Skeleton>
							<Skeleton
								variant="rectangular"
								sx={{
									width: "5px",
									height: "67.5px",
									flexShrink: 0,
								}}
							></Skeleton>
						</Box>
						<Box
							sx={{
								width: "100%",
								height: "150px",
								p: { xs: "8px 21.5px", md: "8px 16px" },
							}}
						>
							<Skeleton
								variant="rounded"
								sx={{
									width: "100%",
									height: "100%",
								}}
							></Skeleton>
						</Box>
					</Box>
					<Box
						sx={{
							display: "flex",
							width: "100%",
						}}
					>
						<Box
							sx={{
								width: { xs: "0", md: "100%" },

								height: "150px",
								p: { xs: "8px 21.5px", md: "8px 16px" },
							}}
						>
							<Skeleton
								variant="rounded"
								sx={{
									display: { xs: "none", md: "block" },

									width: "100%",
									height: "100%",
								}}
							></Skeleton>
						</Box>
						<Box
							sx={{
								// width: "5px",
								position: "relative",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								height: "150px",
								flexShrink: 0,
							}}
						>
							<Skeleton
								variant="rectangular"
								sx={{
									width: "5px",
									height: "67.5px",
									flexShrink: 0,
								}}
							></Skeleton>
							<Skeleton
								variant="circular"
								sx={{
									// position: "absolute",
									// top: "calc(50% - 7.5px)",
									width: "15px",
									height: "15px",
									flexShrink: 0,
								}}
							></Skeleton>
							<Skeleton
								variant="rectangular"
								sx={{
									width: "5px",
									height: "67.5px",
									flexShrink: 0,
								}}
							></Skeleton>
						</Box>
						<Box
							sx={{
								width: "100%",
								height: "150px",
								p: { xs: "8px 21.5px", md: "8px 16px" },
							}}
						>
							<Skeleton
								variant="rounded"
								sx={{
									display: { xs: "block", md: "none" },
									width: "100%",
									height: "100%",
								}}
							></Skeleton>
						</Box>
					</Box>
				</>
			))}
		</Column>
	);
}
