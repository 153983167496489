import { Box, SxProps } from "@mui/material";

export function Divider({
	className = "",
	sx,
}: {
	className?: string;
	sx?: SxProps;
}) {
	return (
		<Box
			component={"hr"}
			className={className}
			sx={{
				width: "100%",
				border: "solid 3px",

				borderImage:
					"conic-gradient(at 0% 7%,#e84e0eeb 10%, #000000 141%) 1 100",

				...sx,
			}}
		/>
	);
}
