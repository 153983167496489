import { Box } from "@mui/material";

export function StoryDivider() {
	return (
		<Box
			// className = "animateOnScroll fademe"

			sx={{
				background: "#4a4a49",
				display: "flex",
				width: "5px",
				position: "relative",
				justifyContent: "center",
				alignItems: "center",
				flexShrink: 0,
			}}
		>
			<Box
				sx={{
					height: "20px",
					width: "20px",
					borderRadius: "100%",
					position: "absolute",
					background: "#4a4a49",
				}}
			/>
		</Box>
	);
}
