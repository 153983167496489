import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Column } from "../Section/column";
import { Row } from "../Section/row";

export function VideoBanner() {
	const [phraseIndex, setPhraseIndex] = useState<number>(0);
	const [videoIndex, setVideoIndex] = useState<number>(0);

	const [show, setShow] = useState<Boolean>(false);

	const videos = [
		"/assets/videos/Cell_And_DNA_Biological_Concept_source_2125053.mp4",
		"/assets/videos/3D_Biological_DNA_Helix_source_2362830.mp4",
		"/assets/videos/Sperm_Cells_Floating_Around_source_2120124_1.mp4",
	];

	const phrases = [
		"Quality Medical Supplies, Delivered.",
		"Innovative Solutions for Better Health",
		"Dedicated to Healthcare Excellence",
		"Your Health, Our Commitment.",
	];

	useEffect(() => {
		setShow(true);
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			setShow(false);

			setTimeout(() => {
				setPhraseIndex((phraseIndex + 1) % phrases.length);
				setVideoIndex((videoIndex + 1) % videos.length);
				setShow(true);
			}, 1300);
		}, 7000);
		return () => {
			clearInterval(interval);
		};
	});

	return (
		<Row
			sx={{
				flexWrap: { md: "unset", xs: "wrap-reverse" },
				minHeight: { xs: "calc(100vw * 3 / 4 )", md: "720px" },
				m: 0,
				position: "relative",
				flexDirection: "row-reverse",
			}}
		>
			<Column
				className={show ? "show" : ""}
				sx={{
					WebkitMaskImage: {
						xs: "linear-gradient(180deg, #0000009c 50%, transparent 95%)",
						md: "linear-gradient(-90deg, black 20%, transparent 100%)",
					},

					height: { xs: "100%", md: "unset" },

					position: { xs: "absolute", md: "relative" },

					maxWidth: { xs: "unset", md: "60%" },
					top: "10px",
					opacity: "0%",
					transition: "all 1.3s cubic-bezier(0.4, 0, 0.2, 1)",

					"&.show": {
						top: "0px",
						opacity: "100%",
					},
				}}
			>
				<Box
					key={videoIndex}
					sx={{
						height: { xs: "100%", md: "unset" },
						position: "absolute",
						right: {
							xs: "unset",
							md: videoIndex == 0 ? 0 : "-150px",
						},
						bottom: { xs: "0", md: "unset" },
					}}
					component={"video"}
					autoPlay
					muted
					playsInline
					loop
				>
					<source src={videos[videoIndex]} type="video/mp4" />
				</Box>
			</Column>
			<Column
				sx={{
					alignItems: { md: "flex-end", xs: "center" },
					// height: "100%",
					// position: { xs: "absolute", md: "relative" },
					// bottom: 0,
					// padding: "10px",
					maxWidth: { xs: "100%", md: "60%" },
					display: "flex",
					// filter: "drop-shadow(0px 0px 1px #0000004d)",
				}}
			>
				<Typography
					variant="h3"
					className={show ? "show" : ""}
					sx={{
						fontWeight: "bold",
						width: { xs: "100%", md: "70%" },
						overflow: "hidden",
						opacity: "0%",
						position: "relative",
						// minHeight: "150px",
						display: "flex",
						justifyContent: { xs: "center", md: "unset" },
						textAlign: { xs: "center", md: "unset" },
						top: "10px",
						transition: "all 1.3s cubic-bezier(0.4, 0, 0.2, 1)",
						"&.show": {
							// minHeight: "100%",
							top: "0px",
							opacity: "100%",
						},

						"& span ": {
							whiteSpace: "break-spaces",
							background:
								"conic-gradient(at 8% 7%, rgb(210 85 2 / 92%) 10%, rgb(122 57 0) 141%)",
							WebkitBackgroundClip: "text",
							WebkitTextFillColor: "transparent",
						},
					}}
				>
					<span>{phrases[phraseIndex]}</span>
				</Typography>
			</Column>
		</Row>
	);
}

// return (
// 	<Row sx={{ flexWrap: { md: "unset", xs: "wrap-reverse" } }}>
// 		<Column
// 			sx={{
// 				WebkitMaskImage:
// 					"linear-gradient(75deg, black 40%, transparent 80%)",
// 				transformOrigin: "right",
// 				transform: "scale(2)",
// 				maxWidth: "50%",
// 			}}
// 		>
// 			<video autoPlay muted playsInline loop>
// 				<source
// 					src='/assets/videos/3D_Biological_DNA_Helix_source_2362830.mp4'
// 					type='video/mp4'
// 				/>
// 			</video>
// 		</Column>
// 		<Column
// 			sx={{
// 				alignItems: { md: "flex-start", xs: "center" },
// 				height: "100%",
// 			}}
// 		>
// 			<Typography
// 				variant='h3'
// 				sx={{
// 					fontWeight: "bold",
// 					textAlign: { xs: "center", md: "unset" },
// 					// textShadow: "0px 0px 2px black",
// 				}}
// 			>
// 				We Help You With
// 			</Typography>

// 			<Typography
// 				variant='h2'
// 				className={show ? "show" : ""}
// 				sx={{
// 					fontWeight: "bold",
// 					width: "100%",
// 					overflow: "hidden",
// 					opacity: "0%",
// 					position: "relative",
// 					minHeight: "150px",
// 					display: "flex",
// 					justifyContent: { xs: "center", md: "unset" },
// 					textAlign: { xs: "center", md: "unset" },
// 					top: "10px",
// 					transition: "all 1.3s cubic-bezier(0.4, 0, 0.2, 1)",
// 					"&.show": {
// 						// minHeight: "100%",
// 						top: "0px",
// 						opacity: "100%",
// 					},

// 					"& span ": {
// 						background:
// 							" conic-gradient(at 8% 7%,#e84e0eeb 10%, #000000 141%)",
// 						WebkitBackgroundClip: "text",
// 						WebkitTextFillColor: "transparent",
// 					},
// 				}}
// 			>
// 				<span>{phrases[phraseIndex]}</span>
// 			</Typography>
// 			{/* <Button variant='contained'>Our Services</Button> */}
// 		</Column>
// 	</Row>
// );
