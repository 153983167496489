import { Box, createTheme, ThemeProvider } from "@mui/material";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { Footer } from "./components/footer/footer";
import { NavigationBar } from "./components/navigation/navigation-bar";

import { ToastContainer } from "react-toastify";
import { HomePage } from "./pages/home/home-page";
import { NewsPage } from "./pages/news/news-page";
import { FursanRoutes } from "./routes/Routes";

const theme = createTheme({
	palette: {
		primary: {
			main: "#e84e0e",
		},
	},
});

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Box
				className="App"
				sx={{
					position: "relative",

					bgcolor: "white",
					"& .MuiButton-contained": {
						background:
							"conic-gradient(at 0% 0%,#e84e0eeb 10%, #000000 141%)",
					},
					"& .animateOnScroll.fademe": {
						opacity: "0%",
						position: "relative",
						top: "30px",
						transition:
							"top 1s ease-in-out, opacity 1s ease-in-out",

						"&.animateOnScrollTriggered": {
							opacity: "100%",
							top: "0px",
						},
					},
				}}
			>
				<Box>
					<Router>
						<NavigationBar />
						<Routes>
							{FursanRoutes.map((route) => {
								return (
									<Route
										key={route.name}
										path={route.path}
										element={route.element}
									/>
								);
							})}

							{/* <Route
							key={"Login Page"}
							path={"/admin/login"}
							element={<LoginPage/>}
						/> */}

							<Route
								key={"Fursan - Achievements"}
								path={"/admin/Achievements"}
								element={<NewsPage is_admin={true} />}
							/>

							<Route
								key={"Fursan"}
								path={"*"}
								element={<HomePage />}
							/>
						</Routes>
						<Footer />
					</Router>
				</Box>
				<ToastContainer
					position="bottom-center"
					autoClose={5000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="colored"
				/>
			</Box>
		</ThemeProvider>
	);
}

export default App;
