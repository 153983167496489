import { Image } from "../../interfaces/ImageSlider";
import { StoryType } from "./constants";

export interface IPost {
	id?:number;
	date: Date;
	brief?: string;
	type:StoryType|string|number ;
	image?: Image;
	description?: string;
}


export class Post implements IPost{
	id?:number;
	date: Date;
	brief?: string;
	type:StoryType|string|number ;
	image?: Image;
	description?: string;
    
    constructor(post:IPost) {
	
	  this.id = post.id;  
      this.date = new Date(post.date);  
	  this.brief = post.brief;  
	  this.type = post.type;  
	  this.description = post.description;  

    };

}


export const Months: Array<string> = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];
