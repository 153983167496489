import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, Button, Container, Link, Typography } from "@mui/material";
import { useEffect } from "react";
import { HeaderText } from "../../components/HeaderText/HeaderText";
import { Column } from "../../components/Section/column";
import { Divider } from "../../components/Section/divider";
import { Row } from "../../components/Section/row";
import { Section } from "../../components/Section/section";

export function ClientsPage() {
	useEffect(() => {
		document.title = "Fursan - Clients";
	}, []);
	const clients = [

		{
			name:"King Hussein Cancer Center",
			src:"/assets/images/2x1/KHCC.png",
			url:"https://www.khcc.jo"
		},
		{
			name:"The Jordanian Royal Medical Services",
			src:"/assets/images/2x1/JRMS.png",
			url:"https://jrms.jaf.mil.jo/index.html"
		},
		{
			name:"Al Abdali Hospital",
			src:"/assets/images/2x1/Abdali.png",
			url:"https://www.abdalihospital.com/"
		},
		{
			name:"Abdulhadi Hospital",
			src:"/assets/images/2x1/Abdulhadi.png",
			url:"https://ahhospital.com"
		},
		{
			name:"Al Saudi Hospital",
			src:"/assets/images/2x1/Saudi.png",
			url:"https://www.alsaudihospital.com"
		},
		{
			name:"Medlabs Group",
			src:"/assets/images/2x1/Medlab.png",
			url:"https://www.medlabsgroup.com"
		},
		{
			name:"Biolab",
			src:"/assets/images/2x1/Biolab.svg",
			url:"https://biolab.jo"
		},
		{
			name:"Al Istiklal Hospital",
			src:"/assets/images/2x1/Istiklal.png",
			url:"https://istiklalhospital.com/en/"
		},
		{
			name:"Irbid Specialty Hospital",
			src:"/assets/images/2x1/irbid Speci.png",
			url:"https://ishjo.com"
		},
		{
			name:"Al Kindi Hospital",
			src:"/assets/images/2x1/al-kindi-hospital-logo.png",
			url:"https://al-kindihospital.com/en/"
		},
		{
			name:"The Specialty Hospital",
			src:"/assets/images/2x1/Speciality.png",
			url:"https://www.specialty-hospital.com/en/home/"
		},
		{
			name:"King Abdullah Al Founder Hospital",
			src:"/assets/images/2x1/KAUH.png",
			url:"https://www.kauh.edu.jo"
		},
		{
			name:"Jordan Finland Modern Hospital",
			src:"/assets/images/2x1/Finlandi.png",
			url:"https://jfmhospital.com/en/"
		},
		{	
			name:"Al Amal Hospital",
			src:"/assets/images/2x1/Al Amal.png",
			url:"https://alamalhospital.me"
		},
		{
			name: "Al Shamisani Hospital",
			src: "/assets/images/2x1/Shmeisani.png",
			url: "http://shamisani-hospital.com/",
		},
		{
			name:"Jordan University Hospital",
			src:"/assets/images/2x1/JUH.png",
			url:"http://hospital.ju.edu.jo/Home.aspx"
		},
		{
			name:"Al Hikma Hospital",
			src:"/assets/images/2x1/Hikma.png",
			url:"https://www.hikmahospital.net/"
		},
		{	
			name:"Royal Hospital",
			src:"/assets/images/2x1/Royal.png",
			url:"https://www.royalhospital.jo/"
		},
		{
			name:"Ibn Al Haytham Hosptial",
			src:"/assets/images/2x1/Ibn haytham.png",
			url:"https://ihh.com.jo"
		},
		{
			name:"Al Istishari Hospital",
			src:"/assets/images/2x1/istishari_logo.png",
			url:"https://www.istisharihospital.com/"
		},
	];
	return (
		<Box
			sx={{
				background:
					"conic-gradient(at 0% 100%,  rgba(0, 0, 0, 30%) -15%,white 30%)",

				"& .SectionBox": {
					minHeight: 500,
				},
			}}
		>
			<Container>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						pt: " 100px",
					}}
				>
					<HeaderText
						title=""
						phrases={[
							"Trusted by Professionals, Chosen by Experts",
							"Fursan For Medical Supplies",
						]}
					/>
				</Box>

				<Section titlePrefix="Our Valued" title="Clients">
					<Column>
						<Row className="animateOnScroll fademe">
							<Typography
								variant="h6"
								align="center"
								sx={{ maxWidth: "800px", margin: "auto" }}
							>
								We take pride in serving a diverse clientele across healthcare, research, and academic institutions. Our dedication to high-quality products and exceptional customer service has fostered long-lasting relationships with clients who rely on us for reliable and innovative solutions tailored to their unique needs.
							</Typography>
						</Row>

						<Row
							sx={{ flexWrap: "wrap", justifyContent: "center" }}
						>
							{clients.map((client, index) => (
								<Box
									key={index}
									className="animateOnScroll fademe"
									sx={{
										padding: "20px 0",
										margin: "0 16px",
										objectFit: "contain",
										width: "300px",

										maxHeight: "200px",
										height: "200px",
										minHeight: "200px",
									}}
									component={Link}
									href={client.url}
								>
									<Box
										sx={{
											width: "100%",
											height: "100%",
											objectFit: "contain",
											filter: "saturate(0) contrast(0.5)",
											transition:
												" filter 0.3s ease-in-out",

											"&:hover": {
												filter: "saturate(1)",
											},
										}}
										component={"img"}
										src={client.src}
									/>
								</Box>
							))}
						</Row>
					</Column>
				</Section>
			</Container>
		</Box>
	);
}
