import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, Button, Container, Typography } from "@mui/material";
import { useEffect } from "react";
import { ContactUs } from "../../components/ContactUs/ContactUs";
import { HeaderText } from "../../components/HeaderText/HeaderText";
import { Column } from "../../components/Section/column";
import { Divider } from "../../components/Section/divider";
import { Section } from "../../components/Section/section";
import { Spacer } from "../../components/Section/spacer";
import { GoogleMaps } from "./map";
export function ContactUsPage() {
	useEffect(() => {
		document.title = "Fursan - Contact Us";
	}, []);

	return (
		<Box
			sx={{
				background:
					"conic-gradient(at 0% 100%,  rgba(0, 0, 0, 30%) -15%,white 30%)",

				"& .SectionBox": {
					// padding: 0,
					minHeight: 500,
				},
			}}
		>
			<Container>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						pt: " 100px",
					}}
				>
					<HeaderText
						title=""
						phrases={[
							"Reach out for Excellence.",
							"Your Questions, Our Solutions.",
							"Bridge for better Healthcare.",
						]}
					/>
				</Box>

				<Section>
					<ContactUs />
				</Section>
				<Divider className="animateOnScroll fademe" />

				<Section
					containerSx={{
						flexDirection: { xs: "column-reverse", md: "row" },
					}}
				>
					<GoogleMaps />
					<Spacer h={70} v={10} />
					<Column
						sx={{
							textAlign: { xs: "center", md: "left" },
							alignItems: {
								xs: "center",
								md: "flex-start",
							},
						}}
					>
						<Typography
							className="animateOnScroll fademe"
							variant="h3"
							sx={{
								fontWeight: "bold",
							}}
						>
							Or Pay Us a
						</Typography>
						<Typography
							className="animateOnScroll fademe"
							variant="h1"
							sx={{
								fontWeight: "bold",
								"&": {
									background:
										"conic-gradient(at 42% 2%,#e84e0eeb 10%, #000000 141%)",
									WebkitBackgroundClip: "text",
									WebkitTextFillColor: "transparent",
								},
							}}
						>
							Visit
						</Typography>
						<Typography
							className="animateOnScroll fademe"
							variant="h6"
						>
							Location: AlBaraka 1, King Abdullah Street Next to
							Zain company, Al Saudi Complex 391, Amman
							Coordinates: 31.96720485229607, 35.84277813578804
						</Typography>
					</Column>
				</Section>
			</Container>
		</Box>
	);
}
